const validateEmailInput: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.emailInput') || null;
    const email: string = input?.value || '';
    const isRequired: boolean = parent?.classList.contains('required');

    parent.classList.remove('invalid');

    if (isRequired) {
      const isValidEmail: boolean = getIsValidEmail(email);

      if (!isValidEmail) parent.classList.add('invalid');

      return;
    }

    if (email !== '') {
      const isValidEmail: boolean = getIsValidEmail(email);

      if (!isValidEmail) parent.classList.add('invalid');

      return;
    }
  } catch (error) {
    console.error(error);
  }
};

const clearEmailInputError: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.emailInput') || null;

    parent.classList.remove('invalid');
  } catch (error) {
    console.error(error);
  }
};

const setEmailInputError: (emailInputID: string) => void = emailInputID => {
  try {
    const emailInput: any = document.getElementById(emailInputID);
    const parent: any = emailInput?.closest('.emailInput') || null;

    parent.classList.add('invalid');
  } catch (error) {
    console.error(error);
  }
};
const getIsValidEmail: (email: string) => boolean = email => {
  try {
    const regex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!regex.test(email) || email === '') return false;

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const relocateEmailInputValueByIdToNewInputByIdAndReturnSuccess: (
  emailInputID: string,
  newInputID: string
) => boolean = (emailInputID, newInputID) => {
  try {
    const emailInput: any = document.getElementById(emailInputID);
    const newInput: any = document.getElementById(newInputID);
    const emailInputValue: string = emailInput?.value || '';

    newInput.value = emailInputValue;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
